import React from "react";
import Card from "./style";

export default function PricingCard2({
  backgroundColor,
  modo,
  cardTitle,
  amount,
  info,
  card,
  ...rest
}) {
  return (
    <Card backgroundColor="#fff" {...rest}>
      <Card.Top backgroundColor={backgroundColor}>
        <Card.Title as="h3">
          <span>{cardTitle}</span>
          <span style={{ fontSize: "1rem" }}>
            {card
              ? card === "2"
                ? "Incluye todo el nivel Esencial"
                : "Incluye todo el nivel Premium"
              : ""}
          </span>
          <span style={{ fontSize: "1rem" }}>
            {card
              ? card === "2"
                ? "Fondeo mínimo $200,000"
                : "Fondeo mínimo $500,000"
              : "Fondeo mínimo $20,000"}
          </span>
        </Card.Title>
        <Card.Text>{modo === "Anual" ? "Anual" : "Mensual"}</Card.Text>
        <Card.Price>
          <Card.PriceText alignSelf="flex-start">$</Card.PriceText>
          <Card.Amount>{amount}</Card.Amount>
          <Card.PriceTextTwo>
            {modo === "Anual" ? "Anual" : "Mensual"}
          </Card.PriceTextTwo>
        </Card.Price>
      </Card.Top>
      <Card.List pt="40px">
        <Card.ListItem iconColor={backgroundColor}>
          {info.dailyContractsLimit}
        </Card.ListItem>
        <Card.ListItem iconColor={backgroundColor}>
          {info.includedSales}
        </Card.ListItem>
        <Card.ListItem iconColor={backgroundColor}>
          {info.valuabotRequests}
        </Card.ListItem>
        <Card.ListItem iconColor={backgroundColor}>
          {info.essentialTools}
        </Card.ListItem>
        <Card.ListItem iconColor={backgroundColor}>
          {info.averageCommission}
        </Card.ListItem>
      </Card.List>
      <Card.Box>
        <Card.Button
          textColor="#fff"
          hoverTextColor="#fff"
          backgroundColor={backgroundColor}
          hoverBackgroundColor={backgroundColor}
          rounded={true}
          sizeX="180px"
          sizeY="56px"
        >
          Get Started
        </Card.Button>
      </Card.Box>
    </Card>
  );
}
