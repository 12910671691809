import React from "react";
import PageWrapper from "~sections/landingpage/components/NavBar/PageWrapper";

import FooterFour from "~sections/landingpage/components/FooterFour";
import PricingSection from "~sections/landingpage/Pricing/PricingSection";
import HeaderButton from "~sections/services/Header";
import ButtonLogin from "~sections/services/ButtonLogin";

const header = {
  headerClasses:
    "site-header site-header--menu-center site-header--services dark-header site-header--sticky site-header--service reveal-header",
  containerFluid: false,
  darkLogo: false,
  buttonBlock: (
    <>
      <ButtonLogin
        className="ms-auto d-none d-xs-inline-flex"
        btnText="Iniciar Sesión"
        mr="15px"
        mrLG="0"
        path= "login"
      />
      <HeaderButton
        className="ms-auto d-none d-xs-inline-flex btn-precios"
        btnText="Registrar"
        mr="15px"
        mrLG="0"
        btnPrecios="btn-precios"
      />
    </>
  ),
};

export default function Precios() {
  return (
    <PageWrapper headerConfig={header}>
      <PricingSection />
      <FooterFour />
    </PageWrapper>
  );
}
