import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Arrow, X } from "./Components/Arrow";
import Card from "./Components/Card/style";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
function createData(name, code, population, size) {
  return { name, code, population, size };
}

const rows = [
  createData("Contratos maximos por día", "10", "20", "30"),
  createData(
    "Porcentaje del pago de comisión sobre ingresos de interés",
    "25%",
    "23%",
    "20%"
  ),
  createData(
    "Porcentaje del pago comisión en el margen de venta (artículos comprados o no recuperados).",
    "25%",
    "23%",
    "20%"
  ),
  createData("Solicitudes a  ValuaBot", "300", "1,000", "3,000"),
  createData(
    "Porcentaje de comisión por venta via marketplace contratos propios",
    "8%",
    "6%",
    "4%"
  ),
  createData(
    "Porcentaje de comisión extra por compra instantanea con pago garantizado 5 dias. (MiEmpeño) ",
    <X color="rgba(0, 0, 0, 0.87)" />,
    "30%",
    "20%"
  ),
  createData(
    "Porcentaje de comisión extra por compra instantanea con pago garantizado 5 dias. (Partner)",
    <X color="rgba(0, 0, 0, 0.87)" />,
    "40%",
    "30%"
  ),
  createData(
    "Crédito para prestamos",
    <X color="rgba(0, 0, 0, 0.87)" />,
    <X/>,
    <Arrow color="#fff" />
  ),
  createData(
    "Cobranza automatizada",
    <Arrow />,
    <Arrow />,
    <Arrow color="#fff" />
  ),
  createData(
    "Manejador de inventario",
    <Arrow />,
    <Arrow />,
    <Arrow color="#fff" />
  ),
  createData(
    "Costo logístico incluido",
    <Arrow />,
    <Arrow />,
    <Arrow color="#fff" />
  ),
  createData(
    "Venta vía Marketplace contratos MiEmpeño (0% comisión extra)",
    <Arrow />,
    <Arrow />,
    <Arrow color="#fff" />
  ),
  createData(
    "Pago por cliente referido",
    <Arrow />,
    <Arrow />,
    <Arrow color="#fff" />
  ),
  createData(
    "Pago por partner referido",
    <Arrow />,
    <Arrow />,
    <Arrow color="#fff" />
  ),
];

export default function StickyHeadTable(mod) {
  const columns = [
    { id: "name", label: "Elige tu plan", minWidth: 170, align: "left" },
    {
      id: "code",
      label: "Esencial",
      precio: `${mod.mod === "Anual" ? "$15,000" : "$1,500"}`,
      minWidth: 170,
      borderColor: "#000",
      paddingLeft: "5rem",
      align: "center",
    },
    {
      id: "population",
      label: `Premium`,
      precio: `${mod.mod === "Anual" ? "$12,990" : "1,299"}`,
      minWidth: 170,
      backgroundColor: "rgb(209 199 255)",
      color: "rgb(55, 65, 217)",
      borderColor: "#fff",
      align: "center",
    },
    {
      id: "size",
      label: `Elite`,
      precio: `${mod.mod === "Anual" ? "0.00" : "0.00"}`,
      minWidth: 170,
      backgroundColor: "rgb(103 82 207)",
      color: "#fff",
      borderColor: "#fff",
      align: "center",
    },
  ];
  const stickyRef = useRef(null);
  const [topSpacing, setTopSpacing] = useState(0);
  useEffect(() => {
    // Función para actualizar la altura
    const updateTopSpacing = () => {
      const externalElement = document.getElementsByClassName("site-header")[0];
      if (externalElement) {
        setTopSpacing(externalElement.offsetHeight);
      }
    };

    // Establece un delay para dar tiempo a la carga de la página y otros elementos
    const timeoutId = setTimeout(updateTopSpacing, 500); // Espera 500 ms

    return () => clearTimeout(timeoutId); // Limpieza en desmontaje
  }, []);
  const [showHeader, setShowHeader] = useState(false);
  useEffect(() => {
    const elemento = document.getElementById("celda");
    console.log(elemento);
    const checkScroll = () => {
      const winScroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      console.log("scroll");
      console.log(winScroll);
      console.log(elemento.getBoundingClientRect().top);
      if (winScroll > elemento.getBoundingClientRect().top - 400) {
        setShowHeader(true);
      } else {
        setShowHeader(false);
      }
    };

    // Añade el escucha de evento al montar
    window.addEventListener("scroll", checkScroll);

    // Limpia el escucha de evento al desmontar
    return () => window.removeEventListener("scroll", checkScroll);
  }, []);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));
  console.log(isLargeScreen);
  return (
    <Paper sx={{ width: "100%", boxShadow: "none" }}>
      <TableContainer
        sx={{ overflow: "visible", padding: "0 2rem", width: "100%", backgroundColor:"#f5f6ff", paddingBottom: "5rem"}}
      >
        <Table
          aria-label="sticky table"
          sx={{
            borderSpacing: "25px 0.75px",
            borderCollapse: "separate",
            paddingRight: isLargeScreen ? "18rem" : "10",
          }}
        >
          <TableHead
            sx={{
              opacity: showHeader ? "1" : "0",
              visibility: showHeader ? "visible" : "hidden",
              transition: "all 0.25s ease",
            }}
          >
            <TableRow sx={{ borderRadius: "5px" }}>
              {columns.map((column, index) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  ref={stickyRef}
                  sx={{
                    top: `calc(${topSpacing}px - 15px)`,
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    width: "20%",
                    border: "none",
                    padding: "0",
                    position: "sticky",
                    backgroundColor: "#f5f6ff",
                    height: "100%",
                    paddingTop: "3rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "1rem",
                      width: "100%",
                      backgroundColor: column.backgroundColor,
                      padding: "16px",
                      borderTopRightRadius: "16px",
                      borderTopLeftRadius: "16px",
                      color: column.color,
                    }}
                  >
                    <p>{column.label}</p>
                    <p style={{ fontSize: "clamp(12px, 4vw, 45px)" }}>
                      {column.precio}
                    </p>
                    {column.id !== "name" ? (
                      <Card.Button
                        textColor="#fff"
                        hoverTextColor="#fff"
                        rounded={true}
                        marginbottom="0"
                        width100={"100%"}
                      >
                        Get Started
                      </Card.Button>
                    ) : (
                      <></>
                    )}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                  id={index === 13 ? "celda" : ""}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          border: "none",
                          backgroundColor: column.backgroundColor,
                          borderBottomRightRadius:
                            index === rows.length - 1 ? "16px" : "0", // Aplicar solo al último elemento
                          borderBottomLeftRadius:
                            index === rows.length - 1 ? "16px" : "0",
                          borderTopLeftRadius: index === 0 ? "16px" : "0",
                          borderTopRightRadius: index === 0 ? "16px" : "0", // Aplicar solo al primer elemento
                          padding: "1rem",
                          width: "20%",
                        }}
                      >
                        <div
                          style={{
                            borderBottom: `0.0625rem solid ${column.borderColor}`,
                            color: column.color,
                            fontSize: "1.2rem",
                          }}
                        >
                          <p>{value}</p>
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
