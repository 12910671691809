import React from "react";
export function Arrow({ color }) {
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="Icon"
      viewBox="0 0 24 24"
      aria-label="Sí"
    >
      <path
        d="M5 11.167 10.895 17 19 7"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  );
}
export function X({ color }) {
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="Icon PricingFeatureTable__grey-icon"
      viewBox="0 0 24 24"
      aria-label="No"
    >
      <path
        d="m5 5 7 7m7 7-7-7m0 0 7-7L5 19"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  );
}
