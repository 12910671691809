import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Pricing from "./style";
import PricingCard from "./Components/Card";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import PricingCard2 from "./Components/Card2";
import StickyHeadTable from "./Table";

const infoCard1 = {
  dailyContractsLimit: "10 contratos limitados por día.",
  includedSales: "100 Ventas incluidas por nuestra plataforma de venta.",
  valuabotRequests: "300 Solicitudes Valuabot.",
  essentialTools: "Herramientas de casa de empeño esenciales.",
  averageCommission: "Comisión 28%~ promedio.",
};
const infoCard2 = {
  dailyContractsLimit: "20 contratos limitados por día.",
  includedSales: "100 Ventas incluidas por nuestra plataforma de venta.",
  valuabotRequests: "1000 Solicitudes Valuabot.",
  essentialTools: "Herramientas de casa de empeño esenciales.",
  averageCommission: "Comisión 25%~ promedio.",
};
const infoCard3 = {
  dailyContractsLimit: "30 contratos limitados por día.",
  includedSales: "100 Ventas incluidas por nuestra plataforma de venta.",
  valuabotRequests: "3000 Solicitudes ValuaBot.",
  essentialTools: "Herramientas de casa de empeño esenciales.",
  averageCommission: "Comisión 20%~ promedio.",
  loanCredit: "Crédito para préstamos.",
};
export default function PricingSection({ ...rest }) {
  const [modo, setModo] = useState("Anual");
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const item = {
    hidden: { y: -200, opacity: 0 },
    visible: (delay) => ({
      y: 0,
      opacity: 1,
      transition: { delay: 0.1 * delay, duration: 0.5 },
    }),
  };
  const handleChange = (event) => {
    setModo(event.target.value);
  };
  return (
    <>
      <div style={{ padding: "3rem 3rem 0 3rem", backgroundColor: "#f5f6ff" }}>
        <Pricing backgroundColor="#f5f6ff" {...rest}>
          <Pricing.Box pb="20px">
            <Row className="align-items-end justify-content-center">
              <Col>
                <Pricing.Box className="text-center text-lg-start">
                  <Pricing.Title>Planes y precios</Pricing.Title>
                </Pricing.Box>
              </Col>
            </Row>
          </Pricing.Box>
          <Row className="justify-content-center">
            <Col lg={3}>
                <Pricing.Centrar>
                  <Pricing.Titulo>
                    <h3 style={{ margin: "2rem 0" }}>Elige tu plan</h3>
                  </Pricing.Titulo>
                  <Pricing.Radio>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="Anual"
                        control={<Radio />}
                        label={
                          <Typography
                            color="black"
                            textAlign={"start"}
                            fontSize={"20px"}
                          >
                            Facturación Anual
                          </Typography>
                        }
                        checked={modo === "Anual"}
                        sx={{ display: "flex", gap: "15px", width: "300px" }}
                      />
                      <FormControlLabel
                        value="Mensual"
                        control={<Radio />}
                        label={
                          <Typography color="black" fontSize={"20px"}>
                            Facturación Mensual
                          </Typography>
                        }
                        checked={modo === "Mensual"}
                        sx={{ display: "flex", gap: "15px", width: "300px" }}
                      />
                    </RadioGroup>
                  </Pricing.Radio>
                </Pricing.Centrar>
            </Col>
            <Col lg={9}>
              <Row>
                <Col
                  md={12}
                  lg={4}
                  style={{
                    marginBottom: "20px",
                    display: "flex",
                    justifyContent: isLargeScreen ? "start" : "center",
                    maxWidth: isLargeScreen ? "360px" : "none",
                  }}
                >
                    <PricingCard
                      cardTitle={"Esencial"}
                      backgroundColor={"#000"}
                      amount={modo === "Anual" ? "17,988" : "1,499"}
                      modo={modo}
                      info={infoCard1}
                    />
                </Col>
                <Col
                  md={12}
                  lg={4}
                  style={{
                    marginBottom: "20px",
                    display: "flex",
                    justifyContent: isLargeScreen ? "start" : "center",
                    maxWidth: isLargeScreen ? "360px" : "none",
                  }}
                >
                    <PricingCard2
                      cardTitle={"Premium"}
                      backgroundColor={"rgb(209 199 255)"}
                      amount={modo === "Anual" ? "12,990" : "1,299"}
                      modo={modo}
                      info={infoCard2}
                      card="2"
                    />
                </Col>
                <Col
                  md={12}
                  lg={4}
                  style={{
                    marginBottom: "20px",
                    display: "flex",
                    justifyContent: isLargeScreen ? "start" : "center",
                    maxWidth: isLargeScreen ? "360px" : "none",
                  }}
                >
                    <PricingCard
                      cardTitle={"Elite"}
                      backgroundColor={"rgb(103 82 207)"}
                      amount={modo === "Anual" ? "0.00" : "0.00"}
                      modo={modo}
                      info={infoCard3}
                      card="3"
                    />
                </Col>
              </Row>
            </Col>
          </Row>
        </Pricing>
      </div>
      <div style={{ height: "100%", backgroundColor: "#f5f6ff" }}>
        <StickyHeadTable mod={modo} />
      </div>
    </>
  );
}
